.about-page-head{
  max-width: 800px;
  margin: 0 auto;
  padding: 70px 16px;
  text-align: center;
  background: rgb(2,0,36);
  background: radial-gradient(circle, rgba(29,115,229,0.20) 0%, rgba(2,0,36,0.1) 39%);
  h1{
    font-size: 42px;
    line-height: 44px;
    color: white;
  }
}
.about-page-body{
  max-width: 1201px;
  margin: 0 auto;
  padding: 0 16px 48px 16px;
  p{
    font-size: 18px;
    color: white;
  }
  .list-holder{
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    .information-box{
      margin: 8px auto;
    }
  }
}
@media (max-width: 768px){
  .about-page-head{
    padding: 38px 16px;
    h1{
      font-size: 32px;
      line-height: 34px;
    }
  }
}
