.faq-wrapper{
  max-width: 1201px;
  margin: 0 auto;
  padding: 0 16px 48px 16px;
  min-height: calc(100vh - 200px);
  small{
    margin-left: 8px;
    margin-top: 2px;
  }
  .faq-collapse{
    background-color: transparent;
  }
  .ant-collapse-item{
    background-color: transparent;
    .ant-collapse-item-active{
      .ant-collapse-header{
        border-bottom: 1px solid rgba(255,255,255,0.3);
      }
    }
  }
  .ant-collapse-header{
    color: white !important;
    background-color: transparent;
    border: 1px solid white;
  }
  .ant-collapse-content{
    color: white !important;
    background-color: transparent;
  }
  .ant-badge-count{
    background-color: #1d73ef;
    font-weight: 600;
    font-size: 15px;
  }
  .faq-page-head{
    max-width: 800px;
    margin: 0 auto;
    padding: 70px 16px;
    text-align: center;
    background: rgb(2,0,36);
    background: radial-gradient(circle, rgba(29,115,229,0.20) 0%, rgba(2,0,36,0.1) 39%);
    h1{
      font-size: 42px;
      line-height: 44px;
      color: white;
    }
  }
  .faq-image{
    margin: 40px auto;
    max-width: 100%;
  }
  h4{
    span{
      color: white;
    }
  }
  span{
    color: white;
  }
  p{
    color: white;
  }
  div{
    color: white;
  }
  td{
    padding: 12px;
    border: 1px solid white;
  }
}
