.header {
  background-color: #161618;
  max-width: 100%;
  padding: 0 16px;
  border-bottom: 1px solid #575656;

  .header-content-wrapper {
    height: 64px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
    column-gap: 20px;
    align-items: center;
    font-family: Roboto, sans-serif;
    flex-direction: row;
    color: white;
    max-width: 1201px;
    width: 100%;

    img {
      width: 40px;
      height: 40px;
    }

    .header-menu {
      background: transparent;
      border-bottom: 0;
      display: inline-block;
      overflow: hidden;
      height: 64px;

      li {
        user-select: none;
        color: rgba(255, 255, 255, 0.8);
        text-transform: capitalize;
        height: 64px;
        line-height: 64px;

        a {
          color: rgba(255, 255, 255, 0.8);
        }

        &:hover {
          color: rgba(24, 144, 255, 0.7);
        }

        &.ant-menu-item-selected {
          color: #1890ff;
        }
      }
    }
  }
}
