.form-wrap{
  max-width: 1201px;
  margin: 42px auto;
  width: 100%;
  padding: 0 30px;
  label{
    color: #0d0c0c;
  }
  .section-block .content{
    margin-bottom: 0;
    .pin-location{
      width: 100%;
      margin-top: 20px;
    }
  }
  input{
    border-radius: 8px;
    height: 48px;
    line-height: 48px;
  }
  textarea{
    border-radius: 8px;
    min-height: 150px;
    max-height: 350px;
    resize: vertical;
  }
  .ant-btn{
    width: 100%;
    max-width: 200px;
    border-radius: 8px;
    height: 44px;
    font-size: 18px;
    float: right;
  }
}
