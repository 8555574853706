.information-box{
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: fit-content;
  text-align: center;
  align-items: center;
  padding: 4px 12px;
  border-radius: 12px;
  background-color: rgba(255,255,255,0.2);
  color: white;
  margin: 4px;
  .icon-wrap{
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      max-width: 100%;
    }
    + .content-wrap{
      margin-left: 8px;
    }
  }
  .content-wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .pre{
      color: #ededef;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      text-transform: uppercase;
    }
    .title{
      color: #ededef;
      font-size: 16px;
      line-height: 20px;
      font-weight: 600;
      letter-spacing: -.035em;
    }
    .content{
      color: #a09fa6;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      letter-spacing: -.0175em;
    }
  }
}
