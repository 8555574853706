@import '~antd/dist/antd.less';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0d0c0c;
  min-height: 100vh;

  .ant-layout{
    background: #0d0c0c;
    .container{
      margin: 0 auto;
      max-width: 1201px;
      width: 100%;
    }
  }

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #2871cc;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #003f9a;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

@brand-gradient: linear-gradient(135deg, #1d73ef, #4e6eee 50%, #1d3e79);

.colorize{
  background-image: @brand-gradient;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.brand-button{
  font-size: 24px;
  height: auto;
  background-image: @brand-gradient;
  background-position: 100% 0;
  background-size: 200% 100%;
  transition: 0.2s;
  border-radius: 6px;
  display: inline-block;
  border: none;
  min-width: 180px;
  .anticon{
    vertical-align: baseline;
  }
  &:focus,
  &:hover{
    background-image: @brand-gradient;
    background-position: 0 0;
    background-size: 200% 100%;
    transition: 0.2s;
    border: none;
  }
}
