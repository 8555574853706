.pin-location{
  padding: 16px 24px;
  background-color: rgba(0,0,0,0.2);
  display: flex;
  width: fit-content;
  border: 1px solid rgba(255,255,255,0.2);
  border-radius: 12px;
  align-items: center;
  .icon-wrap{
    text-align: center;
    .anticon{
      color: white;
      font-size: 28px;
    }
    margin-right: 16px;
  }
  .content-wrap{
    .title{
      color: white;
      font-weight: bold;
      margin: 0;
      text-align: left;
    }
    .location{
      color: white;
      margin: 0;
      text-align: left;
    }
  }
}
