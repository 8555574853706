.section-block-header{
  background-color: rgba(255,255,255,0.9);
  padding: 32px 24px;
  border-radius: 0;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  width: 100%;
  max-width: 1201px;
  margin: 0 auto;
  + .section-block{
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    padding: 16px 24px 32px 24px;
  }
}
.section-block{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  align-items: center;
  border-radius: 22px;
  padding: 16px 24px 32px 24px;
  width: 100%;
  max-width: 1201px;
  margin: 0 auto 20px auto;
  background-image: linear-gradient(135deg, #1d73ef, #4e6eee 50%, #1d3e79);
  color: #ededef;
  .block-header{
    margin: 0 auto;
  }
  img{
    max-width: 100%;
    max-height: 185px;
  }
  .title{
    margin: 0 auto;
    font-weight: bold;
    letter-spacing: 0.5px;
    &:before{
      display:none !important;
    }
  }
  .content{
    margin-bottom: 16px;
    color: #ededef;
    font-size: 18px;
    line-height: 22px;
  }
  .extra{
    max-width: 100%;
  }
}
