.page-pricing-head{
  padding: 48px 16px;
  h1{
    color: white;
    text-align: center;
    font-size: 32px;
    line-height: 32px;
  }
  h2{
    color: white;
    text-align: center;
    font-size: 24px;
    line-height: 24px;
  }
}
.page-pricing-body{
  max-width: 1201px;
  margin: 0 auto;
  padding: 0 16px 48px 16px;
  .section-block{
    max-width: 550px;
    min-height: 500px;
    h1{
      color: white;
      text-align: center;
      font-size: 32px;
      line-height: 32px;
    }
    h2{
      color: white;
      text-align: center;
      font-size: 24px;
      line-height: 24px;
    }
    &-header{
      margin-top: 48px;
      max-width: 550px;
      padding: 24px 16px;
      h1{
        color: #1d73ef;
        font-weight: bold;
        margin: 0;
        text-align: center;
      }
    }
    .title{
      h1{
        font-weight: bold;
        font-size: 52px;
        position: relative;
        display: inline-block;
        margin-top: 16px;
        sup{
          font-weight: normal;
          font-size: 24px;
          position: absolute;
          left: -15px;
          top: 0;
        }
      }
    }
    .content{
      .repeated-component{
        text-align: center;
        .information-box{
          text-align: center;
          margin: 8px auto;
          b{
            color: white;
            text-shadow: 0 0 3px #1d73ef;
          }
        }
      }
    }
  }
  p{
    color: white;
    div{
      margin-top: 16px;
      padding-left: 16px;
    }
  }
}
