.timeline{
  max-width: 1050px !important;
  .information-box {
    margin: 10px;
    .content-wrap .content {
      color: white;
      margin-bottom: 0;
    }
  }
  .entry .title{
    height: auto !important;
    width: 49% !important;
  }
  .entry .body{
    width: 50% !important;
  }
  &:before{
    left: calc(48% + 6px) !important;
  }
  .entry .body p{
    margin-bottom: 0;
  }
  .entry .title:before{
    right: -5px !important;
  }
  .flex-box-timeline{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .center{
    display: flex;
    justify-content: center;
  }
}


@media (max-width: 768px){
  .timeline {
    .entry .title {
      padding-left: 35px;
      width: 100%;
    }

    .entry .title:before {
      left: 6px !important;
    }

    .entry .title {
      height: auto !important;
      width: 100% !important;
    }

    .entry .body {
      width: 100% !important;
    }
    &:before {
      left: 12px !important;
    }
    .section-block-header + .section-block{
      padding: 8px 12px 16px 12px;
    }
  }
}

.how-it-works-page-head{
  max-width: 890px;
  margin: 0 auto;
  padding: 70px 16px;
  text-align: center;
  background: rgb(2,0,36);
  background: radial-gradient(circle, rgba(29,115,229,0.20) 0%, rgba(2,0,36,0.1) 39%);
  h1{
    font-size: 42px;
    line-height: 44px;
    color: white;
  }
}
