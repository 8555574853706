.footer{
  background-color: #161618;
  max-width: 100%;
  padding: 32px 16px;
  border-top: 1px solid #575656;
  .footer-content-wrapper{
    margin: 0 auto;
    align-items: center;
    font-family: Roboto, sans-serif;
    flex-direction: row;
    color: white;
    max-width: 1201px;
    width: 100%;
    .first-row{
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      column-gap: 20px;
      .footer-logo{
        width: 65px;
        height: 65px;
      }
      .footer-sitemap{
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-left: 20px;
        li{
          min-width: 120px;
          min-height: 32px;
          padding: 5px 16px;
          cursor: default;
          transition: 0.3s;
          position: relative;
          &:before{
            width: 0;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            transition: 0.3s;
            background-color: rgba(255,255,255, 0.2);
          }
          &:not(.no-hover):hover{
            cursor: pointer;
            &:before{
              width: 100%;
              transition: 0.3s;
            }
          }
          a{
            color: white;
          }
        }
      }
    }
    .second-row{
      margin-top: 16px;
      .copyright{
        text-align: center;
      }
    }
  }
}
@media (max-width: 576px){
  .footer{
    .footer-content-wrapper{
      .first-row{
        flex-direction: column;
        row-gap: 16px;
        .footer-logo{
          margin: 0 auto;
        }
        .footer-sitemap{
          margin-left: -16px;
          li{
            text-align: center;
          }
        }
      }
    }
  }
}
