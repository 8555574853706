.dp_wrapper{
  color: white;
  max-width: 1201px;
  margin: 0 auto;
  padding: 48px 16px 48px 16px;
  span{
    line-height: 16px;
  }
  ol{
    margin-top: 16px;
    margin-bottom: 20px;
  }
  li{
    margin-top: 12px;
    span{
      line-height: 16px;
    }
  }
  .notion-header__title{
    color: white !important;
  }
}
