.block-header{
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: fit-content;
  text-align: center;
  align-items: center;
  padding: 16px 32px;
  .count-wrap{
    color: black;
    background-color: rgba(255,255,255,0.8);
    min-width: 40px;
    height: 40px;
    width: fit-content;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    font-weight: bold;
  }
  .title{
    margin-top: 8px;
    font-size: 27px;
    color: white;
  }
}
