.icon-slider{
  overflow: hidden;
  max-width: 100%;
  .slider-inner{
    display: flex;
    flex-direction: row;
    animation-name: moveSlideshow;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    .slide{
      margin-right: 12px;
      margin-bottom: 12px;
    }
  }
}

@keyframes moveSlideshow {
  100% {
    transform: translateX(-50%);
  }
}
