.globe-section {
  .scene-container {
    canvas {
      width: 100% !important;
    }
  }
}
.globe-content{
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: inline-table;
  color: white;
  text-align: center;
  padding: 145px 16px 0 16px;
  max-width: 1201px;
  width: 100%;
  h1{
    color: white;
    font-size: 40px;
    margin:0;
  }
  p{
    font-size: 32px;
    margin:0;
  }
  .brand-button{
    margin: 16px auto 0 auto;
  }
}

.intro-section{
  padding: 60px 16px 24px 16px;
  max-width: 1201px;
  width: 100%;
  margin: 0 auto;
  .section-block{
    margin-bottom: 36px;
  }
  h2{
    color: white;
    font-size: 32px;
  }
  .step-1-extra-wrapper{
    display: flex;
    justify-content: center;
    column-gap: 16px;
  }
  .step-2-extra-wrapper{
    .repeated-component{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  .step-3-and-4{
    overflow: hidden;
    .ant-col{
      display: inline-flex;
      align-self: stretch;
      img{
        max-width: 500px;
        width: 100%;
      }
    }
  }
  .centered{
    text-align: center;
  }
}

.slider-section{
  padding: 48px 0;
  h2{
    color: white;
    font-size: 32px;
    text-align: center;
    line-height: 32px;
    padding: 0 16px;
  }
  p{
    color: white;
    font-size: 18px;
    text-align: center;
    margin-bottom: 48px;
    padding: 0 16px;
  }
}
.after-slider-section{
  padding: 32px 16px;
  h2{
    color: white;
    font-size: 32px;
    text-align: center;
    line-height: 32px;
    padding: 0 16px;
  }
  p{
    color: white;
    font-size: 18px;
    text-align: center;
    margin-bottom: 48px;
    padding: 0 16px;
  }
}

.before-footer-section{
  padding: 0 16px 48px 16px;
  h1{
    font-size: 48px;
    text-align: center;
    margin-bottom: 48px;
    line-height: 48px;
  }
  p{
    color: white;
    margin: 0;
    padding: 0;
    margin-bottom: 18px;
    text-align: center;
    line-height: 1;
    &.before-footer-1{
      font-size: 30px;
      opacity: 0.9;
    }
    &.before-footer-2{
      font-size: 31px;
      opacity: 0.8;
    }
    &.before-footer-3{
      font-size: 32px;
      opacity: 0.7;
    }
    &.before-footer-4{
      font-size: 33px;
      opacity: 0.6;
    }
    &.before-footer-5{
      font-size: 34px;
      opacity: 0.5;
    }
    &.before-footer-6{
      font-size: 35px;
      opacity: 0.4;
    }
    &.before-footer-7{
      font-size: 36px;
      opacity: 0.3;
    }
  }
}

@media (max-width: 991px){
  .globe-content {
    padding-top: 72px;
    h1{
      font-size: 32px;
      line-height: 34px;
      margin-bottom: 16px;
    }
    p{
      font-size: 24px;
      line-height: 24px;
      margin-bottom: 16px;
    }
  }
  .intro-section{
    padding-top: 24px;
  }
  .slider-section{
    p{
      font-size: 20px;
    }
  }
  .before-footer-section{
    h1{
      font-size: 32px;
      line-height: 32px;
      margin-bottom: 32px;
    }
    p{
      &.before-footer-1{
        font-size: 20px;
      }
      &.before-footer-2{
        font-size: 21px;
      }
      &.before-footer-3{
        font-size: 22px;
      }
      &.before-footer-4{
        font-size: 23px;
      }
      &.before-footer-5{
        font-size: 24px;
      }
      &.before-footer-6{
        font-size: 25px;
      }
      &.before-footer-7{
        font-size: 26px;
      }
    }
  }
}

@media (max-width: 576px){
  .globe-section{
    max-height: 364px;
  }
  .globe-content {
    padding-top: 72px;
  }
  .intro-section{
    .step-1-extra-wrapper{
      flex-direction: column;
      align-items: center;
      .pin-location:first-child{
        margin-bottom: 16px;
      }
    }
  }
}
