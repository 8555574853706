.rewards-wrapper {
  max-width: 1201px;
  margin: 0 auto;
  padding: 0 16px 48px 16px;

  .rewards-page-head {
    max-width: 800px;
    margin: 0 auto;
    padding: 70px 16px;
    text-align: center;
    background: rgb(2, 0, 36);
    background: radial-gradient(circle, rgba(29, 115, 229, 0.20) 0%, rgba(2, 0, 36, 0.1) 39%);

    h1 {
      font-size: 42px;
      line-height: 44px;
      color: white;
    }

    p {
      color: #a09fa6;
      font-weight: 300;
      margin: 0;
      font-size: 18px;
      line-height: 18px;
    }
  }

  .rewards-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 8px;
    column-gap: 8px;

    .reward-card {
      width: 350px;
      max-width: 100%;

      .reward-link {
        display: block;
        overflow: hidden;
        padding: 15px;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 8px;

        .reward-left {
          display: flex;
          align-items: center;

          .reward-img {
            width: 40px;
            height: 40px;
            margin-right: 12px;
          }

          .reward-promo {
            max-width: 280px;
            width: 95%;
            text-overflow: ellipsis;
            color: #ededef;
            font-size: 13px;
            line-height: 13px;
            text-align: left;
            text-transform: uppercase;
            white-space: nowrap;
            overflow: hidden;
          }

          .div-block-194 {
            margin: 3px 0;

            h1 {
              width: 95%;
              margin-top: 0;
              margin-bottom: 0;
              margin-left: 0;
              color: #ededef;
              font-size: 15px;
              line-height: 15px;
              font-weight: 600;
              letter-spacing: 1px;
              max-width: 280px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }

          .reward-summary {
            max-width: 280px;
            width: 95%;
            text-overflow: ellipsis;
            color: #a09fa6;
            font-size: 13px;
            line-height: 13px;
            font-weight: 400;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }
    }
  }
}
